import { missionApi } from '@/services/base-axios/missionApi'
import type { FindAllData } from '@/types'
import type { FindBadgeDto, IBadge } from '@/types/badge.type'

const CONTROLLER = 'badge'

class Badge {
  async getById(id: string): Promise<IBadge> {
    const { data } = await missionApi.get(`${CONTROLLER}/${id}`)
    return data
  }

  async getByIds(badgeIds: string[]): Promise<FindAllData<IBadge>> {
    if (badgeIds.length === 0)
      return {
        data: [],
        total: 0,
      }

    const { data } = await missionApi.get(`${CONTROLLER}`, {
      params: {
        badgeIds,
      },
    })
    return data
  }
  async find(params: FindBadgeDto) {
    return missionApi.get(CONTROLLER, { params })
  }
}

export const BadgeService = new Badge()
